<!-- @format -->

<template>
  <b-card no-body>
    <div class="m-2">
      <div class="alert alert-warning custom-alert" role="alert">
    Note: By default, data displayed is for the current month.
  </div>
  <!-- Table Top -->
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col md="5" sm="4" xl="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>Page</label>
          <v-select v-model="pageSize" :options="pageSizeOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
        </b-col>

        <!-- Search -->
        <b-col>
          <div class="d-flex align-items-center justify-content-end">
          <!--    <b-form-input v-model="userId" class="d-inline-block mr-1" placeholder="请输入会员ID" /> -->
            <BFormDatepicker v-model="startTime" class="d-inline-block mr-1" placeholder="Start time" :reset-button="true" />
            <BFormDatepicker v-model="endTime" class="d-inline-block mr-1" placeholder="End time" :reset-button="true" />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      bordered
      tbody-tr-class="text-center"
      :selectable="selectable"
      select-mode="multi"
      @row-clicked="contentChange"
      ref="orderListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative text-center"
      style="min-height: 400px"
      
    />
    <p class="text-center mt-2">Click on a row to view details.</p>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="pageSize" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal id="detailModal" title="Detail" :hide-footer="true" size="xl">
  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block @click="downLoad"> Download </b-button>
  <table class="table table-striped table-bordered">
  <thead>
    <tr>
      <th>#</th> <!-- 序号列 -->
      <th><strong>BRAND</strong></th>
      <th>POLICY NO.</th>
      <th>INSURED</th>
      <th><strong>COMMISSION</strong></th>
      <th>DATE</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item, index) in selectedItem.xiangqing" :key="item.id">
  <td style="font-size: 1.2rem; font-weight: bold; color: #333;">{{ index + 1 }}</td>
  <td style="font-size: 1.2rem; font-weight: bold; color: #555;">{{ item.brand_title }}</td>
  <td style="font-size: 1.2rem; font-weight: bold; color: #777;">{{ item.id }}</td>
  <td style="font-size: 1.2rem; font-weight: bold; color: #444;">{{ item.protect_user }}</td>
  <td style="font-size: 1.2rem; font-weight: bold; color: hsl(0, 0%, 4%);">{{ parseFloat(item.change_moeny).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
  <td style="font-size: 1.2rem; font-weight: bold; color: #080808;">{{ item.pay_time }}</td>
</tr>

  </tbody>
</table>

</b-modal>

  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BTable, BPagination, BFormDatepicker } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { getCurrentInstance, ref } from '@vue/composition-api'
import 'flatpickr/dist/flatpickr.css'
import useInvoicesList from './useOrderList'
import orderModule from './OrderManagerList'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

export default {
  components: {
    BFormDatepicker,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'user-order'
    const { proxy } = getCurrentInstance()
    const selectedItem = ref({}) // 新增
    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderModule)
    }

    const {
      startTime,
      endTime,
      userId,
      fetchInvoices,
      tableColumns,
      pageSize,
      currentPage,
      totalInvoices,
      dataMeta,
      pageSizeOptions,
      orderListTable,
      refetchData,
    } = useInvoicesList(proxy)

    const selectable = ref(true)

    const piliangArray = ref([])
    const contentChange = (item, index, event) => {
      if (piliangArray.value.indexOf(item.id) == -1) {
        piliangArray.value.push(item.id)
      } else {
        piliangArray.value.splice(piliangArray.value.indexOf(item.id), 1)
      }
      selectedItem.value = item // 新增
      proxy.$bvModal.show('detailModal') // 新增
    }
    

    const downLoad = () => {
  document.body.style.cursor = 'wait';

  // 创建jsPDF对象
  const doc = new jsPDF('l', 'mm', 'a4');

  // 获取数据
  const dataFromApi = selectedItem.value;

  // 设置公司logo
  const logo = 'https://www.myonewow.com/zylogo.jpg';
  doc.addImage(logo, 'PNG', 0, 0, 60, 30);

  // 设置公司信息
  doc.setFontSize(12);
  doc.setFont(undefined, 'normal');
  doc.text('The One Pioneer LLC', 10, 26);
  doc.text('150 N Santa Anita Ave Suite 200', 10, 32);
  doc.text('Arcadia CA 91006', 10, 38);

  // 设置代理信息
  doc.setFontSize(12);
  doc.text('Commission Statement', 245, 12, null, null, 'center');
  doc.text(`Period: ${dataFromApi.date_range}`, 245, 18, null, null, 'center');
  doc.text(`Agent Name: ${dataFromApi.real_name}`, 245, 24, null, null, 'center');
  doc.text(`Agent ID: ${dataFromApi.id}`, 245, 30, null, null, 'center');

  // 格式化金额为美元格式
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const formattedMoneys = formatter.format(dataFromApi.moneys);
  doc.text(`Total: ${formattedMoneys}`, 245, 36, null, null, 'center');

  // 添加免责声明的函数
  const addDisclaimerToLastPage = () => {
    doc.setFontSize(12);
    doc.setFont(undefined, 'normal');
    const disclaimerText = "Important Disclaimer:\n\n" +
      "Agent hereby certifies that he/she is actively licensed in the appropriate policy state to legally receive commissions/overrides.\n\n" +
      "By accepting these commission, Agent agrees to reimburse in the event of a chargeback to the above mentioned Policy or Policies \n\n" +
      "within ten(10) working days for the full amount of the chargeback";
    doc.text(disclaimerText, 10, 40);
      // 设置公司logo
  const logo = 'https://myonewow.com/zylogo.jpg';
  doc.addImage(logo, 'PNG', 0, 160, 60, 30);

  // 设置公司信息
  doc.setFontSize(12);
  doc.setFont(undefined, 'normal');
  doc.text('The One Pioneer LLC', 10, 186);
  doc.text('150 N Santa Anita Ave Suite 200', 10, 192);
  doc.text('Arcadia CA 91006', 10, 198);

  // 设置代理信息
  doc.setFontSize(12);
  doc.text('Commission Statement', 245, 172, null, null, 'center');
  doc.text(`Period: ${dataFromApi.date_range}`, 245, 180, null, null, 'center');
  doc.text(`Agent Name: ${dataFromApi.real_name}`, 245, 186, null, null, 'center');
  doc.text(`Agent ID: ${dataFromApi.id}`, 245, 192, null, null, 'center');

  // 格式化金额为美元格式
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const formattedMoneys = formatter.format(dataFromApi.moneys);
  doc.text(`Total: ${formattedMoneys}`, 245, 198, null, null, 'center');
  };

  // 分页处理
  const modalContent = document.querySelector('#detailModal .table');
  let yOffset = 0;

  // 循环捕捉和添加数据
  const captureAndAddPage = () => {
    let pageHeight = yOffset === 0 ? 800 : 1100; // 第一页和其他页的高度不同

    html2canvas(modalContent, {
      backgroundColor: '#fff',
      scale: 2,
      removeContainer: true,
      allowTaint: true,
      height: pageHeight,
      y: yOffset,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      const pdfWidth = doc.internal.pageSize.width;
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      // 设置顶部边距
      const topMargin = yOffset === 0 ? 45 : 10;

      doc.addImage(imgData, 'JPEG', 0, topMargin, pdfWidth, pdfHeight);

      yOffset += pageHeight;

      if (yOffset < modalContent.clientHeight) {
        doc.addPage();
        captureAndAddPage();
      } else {
        // 循环结束后，在新的一页添加免责声明
        doc.addPage(); // 添加新的一页
        addDisclaimerToLastPage(); // 在新的一页上添加免责声明
        const pdfName = `Earnings_Record_${dataFromApi.date_range.replace(/\//g, '-')}.pdf`;
        doc.save(pdfName);
        document.body.style.cursor = 'default';
      }
    });
  };

  captureAndAddPage();
};





    return {
      startTime,
      endTime,
      userId,
      piliangArray,
      contentChange,
      selectedItem, // 新增
      selectable,
      fetchInvoices,
      tableColumns,
      pageSize,
      currentPage,
      totalInvoices,
      dataMeta,
      pageSizeOptions,
      orderListTable,
      refetchData,
      downLoad,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 100px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.wocc {
  >>> .dropdown-toggle {
    padding: 0px !important;
  }
}
.custom-alert {
  font-size: 1.25rem; /* 或您希望的大小 */
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.form-control-time {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
  width: 100%;
  height: 2.714rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* 添加放大效果的CSS样式 */
td:hover {
  transform: scale(1.4); /* 将文字放大1.2倍 */
  transition: transform 0.3s ease; /* 添加平滑过渡效果 */
}

</style>
