/** @format */

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInvoicesList(proxy) {
  // Use toast
  const toast = useToast()

  const orderListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: 'ID',
      thStyle: { whiteSpace: 'nowrap' },
    },
    {
      key: 'real_name',
      label: 'NAME',
      thStyle: { whiteSpace: 'nowrap' },
    },
    {
      key: 'level_id',
      label: 'LEVEL',
      thStyle: { whiteSpace: 'nowrap' },
    },
   // {
    //  key: 'tps',
    //  label: 'PAYMENT TP',
    //  thStyle: { whiteSpace: 'nowrap' },
   // },
    {
      key: 'moneys',
      label: 'COMMISSION PAID',
      thStyle: { whiteSpace: 'nowrap' },
    },
    {
      key: 'date_range',
      label: 'DATE',
      thStyle: { whiteSpace: 'nowrap' },
    },
  ]
  const pageSize = ref(100) // pageSize
  const totalInvoices = ref(0) // total
  const currentPage = ref(1) // now Page
  const pageSizeOptions = [100,200] // pageSize Array

  const startTime = ref('')
  const endTime = ref('')
  // const startTime = ref('2023-01-01')
  // const endTime = ref('2023-12-31')
  const userId = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = orderListTable.value ? orderListTable.value.localItems.length : 0
    return {
      from: pageSize.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: pageSize.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    orderListTable.value.refresh()
  }

  watch([currentPage, pageSize, userId, startTime, endTime], () => {
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('user-order/fetchListData', {
        pageSize: pageSize.value,
        page: currentPage.value,
        user_id: userId.value,
        start_time: startTime.value,
        end_time: endTime.value,
      })
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalInvoices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchInvoices,
    tableColumns,
    pageSize,
    currentPage,
    totalInvoices,
    dataMeta,
    pageSizeOptions,
    orderListTable,
    refetchData,
    startTime,
    endTime,
    userId
  }
}
